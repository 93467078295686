class ProspectUtils {
  static verifyCard(account_id, email, phone) {
    fetch(`/card_verification_check?account_id=${account_id}&email=${email}&phone=${phone}`, {headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }})
      .then(FetchUtils.checkResponseStatus)
      .then(response => response.json())
      .then(data => {
        if (JSON.parse(data.valid)) {
          LayoutUtils.hide(document.querySelector('.question[data-type="verification_method"]')?.childNodes);
          LayoutUtils.hide(document.querySelector('.question[data-type="card"]')?.childNodes);
          document.querySelector('.question[data-type="card"]')?.insertAdjacentHTML('beforeend', '<p>Credit Card Previously Approved</p>');
        } else {
          LayoutUtils.show(document.querySelector('.question[data-type="verification_method"]')?.childNodes);
          LayoutUtils.show(document.querySelector('.question[data-type="card"]')?.childNodes);
          document.querySelector('.question[data-type="card"] p')?.remove();
        }
      })
      .catch(err => {
        FetchUtils.handleResponseError(err);
      });
  }
}

export default ProspectUtils;
